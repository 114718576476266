<template>
  <div class="article">
    <transition name="slide-fade-1">
      <div class="tbl_wrap" key="tbl" v-if="selectedIndex == -1">
        <div class="tbl_option">
          <div>
            <h5>수주 현황</h5>
            <div class="input_checkbox">
              <label
                for="checkbox1"
                class="chk_box"
                :class="check_only_not_completed == true ? 'active' : '_'"
              >
                <i class="fa fa-check"></i>
              </label>
              <input
                type="checkbox"
                id="checkbox1"
                @click="$store.commit('toggleCheckOnlyNotCompletedToSales')"
              />
              <label for="checkbox1" class="label_txt"
                >납품 미완료 수주만 보기</label
              >
            </div>
          </div>
          <div class="tbl_opt">
            <span>납품 요청일</span>
            <div>
              <div class="input_text">
                <input
                  type="date"
                  placeholder="YYYY-MM-DD"
                  :value="start_date"
                  @change="setStartDate($event)"
                  v-if="showSearchDate"
                />
              </div>
              <p>~</p>
              <div class="input_text">
                <input
                  type="date"
                  placeholder="YYYY-MM-DD"
                  @change="setEndDate($event)"
                  :value="end_date"
                  v-if="showSearchDate"
                />
              </div>
            </div>
          </div>
        </div>
        <h6>조회수 : {{ sales.length }}건</h6>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl" :class="{ manage: managementMode }">
            <colgroup>
              <col v-for="(n, index) in managementMode ? 8 : 7" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>매출처명</th>
                <th>제품명</th>
                <th>납품 요청일</th>
                <th>수주일</th>
                <th>납품여부</th>
                <th>납품일</th>
                <th>매출액</th>
                <th v-show="managementMode">삭제</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(sale, index) in sales"
                :key="sale.id"
                @click="selectRow(index)"
                :class="{ active: selectedIndex == index }"
              >
                <td class="text_left">
                  {{ getCompanyInfo(sale.company_id).name || '' }}
                </td>
                <td class="text_left">{{ getFirstSalesProduct(index) }}</td>
                <td>{{ sale.delivery_date }}</td>
                <td>{{ sale.input_date }}</td>
                <td>{{ sale.completed ? 'Y' : 'N' }}</td>
                <td>{{ sale.completed ? getLastOutputDay(sale) : '-' }}</td>
                <td class="text_right">&#8361; {{ getSaleCost(index) }}</td>
                <td v-if="managementMode" @click="ShowModal(index)">
                  <button class="tbl_delete_btn">
                    delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div class="aside_management_mode" key="aside" v-if="selectedIndex != -1">
        <div class="head">
          <button type="button" class="btn_prev" @click="selectRow(-1)">
            <h5>수주 현황</h5>
          </button>
          <div>
            <button class="btn_sub1" @click="copyData()" v-if="isPermissionOn">
              복사 후 신규등록
            </button>
          </div>
        </div>
        <div class="aside_cont">
          <p class="warning">
            {{
              managementMode &&
              managementData.vat_id !=
                getCompanyInfo(managementData.company_id).vat
                ? getCompanyInfo(managementData.company_id).vat == 1
                  ? '※ 거래처정보 [부가세별도]로 변경되었습니다.'
                  : getCompanyInfo(managementData.company_id).vat == 2
                  ? '※ 거래처정보 [부가세포함]으로 변경되었습니다.'
                  : '※ 거래처정보 [영세율]으로 변경되었습니다.'
                : ''
            }}
          </p>
          <div class="input_text">
            <label>매출처</label>
            <div class="input_search">
              <input
                type="text"
                :value="getCompanyInfo(managementData.company_id).name || ''"
                readonly
              />
              <!-- <button><i class="fa fa-search"></i></button> -->
            </div>
          </div>
          <div>
            <span>주문내역</span>
            <div class="mes_tbl_wrap">
              <table class="mes_tbl" :class="{ manage: managementMode }">
                <colgroup>
                  <col
                    v-for="(n, index) in managementMode ? 13 : 12"
                    :key="index"
                  />
                </colgroup>
                <thead>
                  <tr>
                    <th>제품명</th>
                    <th>규격</th>
                    <th>과세여부</th>
                    <th>단위</th>
                    <th>수량</th>
                    <th>박스</th>
                    <th>총 수량</th>
                    <th>단가</th>
                    <th>공급가</th>
                    <th>부가세</th>
                    <th>할인액</th>
                    <th>매출액</th>
                    <th v-show="managementMode">
                      삭제
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(sales_product, index) in managementMode
                      ? manage_products
                      : sales_products"
                    :key="(sales_product.id, index)"
                  >
                    <td class="text_left">
                      {{
                        sales_product.parent_product_id != null
                          ? getProductInfo(sales_product.parent_product_id).name
                          : getProductInfo(sales_product.product_id).name || ''
                      }}
                      <br v-if="sales_product.parent_product_id != null" />
                      {{
                        sales_product.parent_product_id != null
                          ? `▶ ${getProductInfo(sales_product.product_id).name}`
                          : ''
                      }}
                    </td>
                    <td class="text_left">
                      {{
                        getProductInfo(sales_product.product_id).standard || ''
                      }}
                    </td>
                    <td>
                      {{
                        getProductInfo(sales_product.product_id).tax !=
                          sales_product.tax_id && managementMode
                          ? (sales_product.tax_id == true ? '과세' : '면세') +
                            ' -> ' +
                            (getProductInfo(sales_product.product_id).tax ==
                            true
                              ? '과세'
                              : '면세')
                          : sales_product.tax_id == true
                          ? '과세'
                          : '면세'
                      }}
                    </td>
                    <td>
                      {{
                        managementMode
                          ? getUnitName(
                              getProductInfo(sales_product.product_id)
                                .stock_unit_id,
                            )
                          : getUnitName(sales_product.unit_id) || ''
                      }}
                    </td>
                    <td>
                      <input
                        type="text"
                        :value="$makeComma(sales_product.quantity)"
                        :disabled="
                          !managementMode ||
                            sales_product.parent_product_id != null
                        "
                        inputmode="decimal"
                        @input="
                          $inputNumber($event, sales_product, 'quantity');
                          checkAndReplaceChildQuantity(sales_product);
                        "
                      />
                      <span class="tbl_sub_info">{{
                        managementData.sales_products.find(
                          x => x.id == sales_product.id,
                        ) != undefined
                          ? $makeComma(
                              managementData.sales_products.find(
                                x => x.id == sales_product.id,
                              ).quantity,
                            )
                          : ''
                      }}</span>
                    </td>
                    <td>
                      <my-selectric
                        :id="`box_unit_selectric${index}`"
                        :watch="sales_product.box_unit_id"
                        :options="box_options"
                        @refresh="checkAndReplaceChildQuantity(sales_product)"
                        :state="
                          !managementMode ||
                          sales_product.parent_product_id != null
                            ? 'disabled'
                            : ''
                        "
                        :index="index"
                        :commit="'setManagementBoxUnitIdToSales'"
                      >
                      </my-selectric>
                    </td>
                    <td class="text_right">
                      {{
                        $makeComma(
                          $decimalMul(
                            $makeNumber($makeNumber(sales_product.quantity)),
                            getBoxQuantity(sales_product.box_unit_id),
                          ),
                        )
                      }}
                    </td>
                    <td>
                      <input
                        type="text"
                        :value="
                          sales_product.parent_product_id != null
                            ? '-'
                            : $makeComma($makeNumber(sales_product.cost))
                        "
                        inputmode="decimal"
                        @input="$inputNumberInt($event, sales_product, 'cost')"
                        :disabled="
                          !managementMode ||
                            sales_product.parent_product_id != null
                        "
                      />
                      <span class="tbl_sub_info">{{
                        managementData.sales_products.find(
                          x => x.id == sales_product.id,
                        ) != undefined
                          ? sales_product.parent_product_id != null
                            ? '-'
                            : $makeComma(
                                managementData.sales_products.find(
                                  x => x.id == sales_product.id,
                                ).cost,
                              )
                          : ''
                      }}</span>
                    </td>
                    <!-- @input="typingCost($event, index)" -->
                    <td class="text_right">
                      {{
                        sales_product.parent_product_id != null
                          ? '-'
                          : `₩ ${$makeComma(
                              calSupplyTax(
                                managementMode
                                  ? getCompanyInfo(managementData.company_id)
                                      .vat
                                  : managementData.vat_id,
                                managementMode
                                  ? getProductInfo(sales_product.product_id).tax
                                  : sales_product.tax_id,
                                $makeNumber(sales_product.quantity) *
                                  getBoxQuantity(sales_product.box_unit_id),
                                $makeNumber(sales_product.cost),
                              ).supply,
                            )}`
                      }}
                    </td>
                    <td class="text_right">
                      {{
                        sales_product.parent_product_id != null
                          ? '-'
                          : `₩ ${$makeComma(
                              calSupplyTax(
                                managementMode
                                  ? getCompanyInfo(managementData.company_id)
                                      .vat
                                  : managementData.vat_id,
                                managementMode
                                  ? getProductInfo(sales_product.product_id).tax
                                  : sales_product.tax_id,
                                $makeNumber(sales_product.quantity) *
                                  getBoxQuantity(sales_product.box_unit_id),
                                $makeNumber(sales_product.cost),
                              ).tax,
                            )}`
                      }}
                    </td>
                    <!-- 할인액 -->
                    <td>
                      <input
                        type="text"
                        :value="
                          sales_product.parent_product_id != null
                            ? '-'
                            : $makeComma(sales_product.discount)
                        "
                        inputmode="decimal"
                        @input="
                          $inputNumberInt($event, sales_product, 'discount');
                          managementData.total_discount = managementMode
                            ? managementData.product_list.length > 0
                              ? calTotalPrice(
                                  managementData.product_list,
                                  $makeNumber(
                                    getCompanyInfo(managementData.company_id)
                                      .vat,
                                  ),
                                  true,
                                ).total_discount
                              : 0
                            : managementData.sales_products.length > 0
                            ? calTotalPrice(
                                managementData.sales_products,
                                managementData.vat_id,
                                false,
                              ).total_discount
                            : 0;
                        "
                        :disabled="
                          !managementMode ||
                            sales_product.parent_product_id != null
                        "
                      />
                      <span class="tbl_sub_info">{{
                        managementData.sales_products.find(
                          x => x.id == sales_product.id,
                        ) != undefined
                          ? sales_product.parent_product_id != null
                            ? '-'
                            : $makeComma(
                                managementData.sales_products.find(
                                  x => x.id == sales_product.id,
                                ).discount,
                              )
                          : ''
                      }}</span>
                    </td>
                    <td>
                      <input
                        :disabled="
                          !managementMode ||
                            sales_product.parent_product_id != null
                        "
                        type="text"
                        :value="
                          sales_product.parent_product_id != null
                            ? '-'
                            : $makeComma(
                                calSupplyTax(
                                  getCompanyInfo(managementData.company_id).vat,
                                  getProductInfo(sales_product.product_id).tax,
                                  $makeNumber(sales_product.quantity) *
                                    getBoxQuantity(sales_product.box_unit_id),
                                  $makeNumber(sales_product.cost),
                                ).total - $makeNumber(sales_product.discount),
                              )
                        "
                        inputmode="decimal"
                        @blur="
                          $event =>
                            ($event.target.value = $makeComma(
                              calSupplyTax(
                                getCompanyInfo(managementData.company_id).vat,
                                getProductInfo(sales_product.product_id).tax,
                                $makeNumber(sales_product.quantity) *
                                  getBoxQuantity(sales_product.box_unit_id),
                                $makeNumber(sales_product.cost),
                              ).total - $makeNumber(sales_product.discount),
                            ))
                        "
                        @keydown.enter="
                          $event =>
                            reCalCost(sales_product, $event.target.value)
                        "
                        @keypress="onlyNumber($event)"
                        @input="$inputNumberIntNoTarget($event)"
                      />
                      <span class="tbl_sub_info">{{
                        managementData.sales_products.find(
                          x => x.id == sales_product.id,
                        ) != undefined
                          ? sales_product.parent_product_id != null
                            ? '-'
                            : $makeComma(
                                $decimalSub(
                                  $makeNumber(
                                    managementData.sales_products.find(
                                      x => x.id == sales_product.id,
                                    ).total_cost,
                                  ),
                                  $makeNumber(
                                    managementData.sales_products.find(
                                      x => x.id == sales_product.id,
                                    ).discount,
                                  ),
                                ),
                              )
                          : ''
                      }}</span>
                    </td>
                    <td
                      v-if="
                        managementData.product_list.length > 0 && managementMode
                      "
                    >
                      <button
                        class="tbl_delete_btn"
                        v-if="sales_product.parent_product_id == null"
                        @click="deleteProductListItem(sales_product, index)"
                      >
                        delete
                      </button>
                    </td>
                  </tr>

                  <tr
                    v-show="managementData.company_id != null && managementMode"
                  >
                    <td>
                      <button @click="showProductSearch = true">
                        <img
                          src="@/assets/images/icon/icon-floating-search.png"
                        />
                      </button>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td v-show="managementMode"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="date_options">
              <div class="receiving_info">
                <div>
                  <div class="input_text">
                    <div>
                      <p class="warning">
                        {{
                          managementMode
                            ? managementData.sales_products.length > 0
                              ? '₩ ' +
                                calTotalPrice(
                                  managementData.sales_products,
                                  managementData.vat_id,
                                  false,
                                ).total_cost
                              : ''
                            : ''
                        }}
                      </p>
                    </div>
                    <label>합계액</label>
                    <input
                      type="text"
                      placeholder=""
                      readonly
                      :value="
                        managementMode
                          ? managementData.product_list.length > 0
                            ? '₩ ' +
                              calTotalPrice(
                                managementData.product_list,
                                getCompanyInfo(managementData.company_id).vat,
                                true,
                              ).total_cost
                            : ''
                          : managementData.sales_products.length > 0
                          ? '₩ ' +
                            calTotalPrice(
                              managementData.sales_products,
                              managementData.vat_id,
                              false,
                            ).total_cost
                          : ''
                      "
                    />
                  </div>
                  <div class="input_text">
                    <div>
                      <p class="warning">
                        {{
                          managementMode
                            ? managementData.sales_products.length > 0
                              ? '₩ ' +
                                calTotalPrice(
                                  managementData.sales_products,
                                  managementData.vat_id,
                                  false,
                                ).non_tax_total_cost
                              : ''
                            : ''
                        }}
                      </p>
                    </div>
                    <label>비과세</label>
                    <input
                      type="text"
                      placeholder=""
                      readonly
                      :value="
                        managementMode
                          ? managementData.product_list.length > 0
                            ? '₩ ' +
                              calTotalPrice(
                                managementData.product_list,
                                getCompanyInfo(managementData.company_id).vat,
                                true,
                              ).non_tax_total_cost
                            : ''
                          : managementData.sales_products.length > 0
                          ? '₩ ' +
                            calTotalPrice(
                              managementData.sales_products,
                              managementData.vat_id,
                              false,
                            ).non_tax_total_cost
                          : ''
                      "
                    />
                  </div>
                </div>
                <div>
                  <div class="input_text">
                    <div>
                      <p class="warning">
                        {{
                          managementMode
                            ? managementData.sales_products.length > 0
                              ? '₩ ' +
                                calTotalPrice(
                                  managementData.sales_products,
                                  managementData.vat_id,
                                  false,
                                ).total_discount
                              : ''
                            : ''
                        }}
                      </p>
                    </div>
                    <label>할인액</label>
                    <input
                      id="total_discount"
                      type="text"
                      inputmode="decimal"
                      placeholder=""
                      :disabled="!managementMode"
                      :readonly="
                        !managementData.product_list.length > 0 &&
                          !managementMode
                      "
                      :value="
                        managementMode
                          ? managementData.product_list.length > 0
                            ? calTotalPrice(
                                managementData.product_list,
                                getCompanyInfo(managementData.company_id).vat,
                                true,
                              ).total_discount
                            : ''
                          : managementData.sales_products.length > 0
                          ? calTotalPrice(
                              managementData.sales_products,
                              managementData.vat_id,
                              false,
                            ).total_discount
                          : ''
                      "
                      @keydown.enter="
                        managementMode
                          ? reCalDiscount(
                              $event,
                              managementData.product_list,
                              getCompanyInfo(managementData.company_id).vat,
                            )
                          : reCalDiscount(
                              $event,
                              managementData.sales_products,
                              managementData.vat_id,
                            )
                      "
                      @keypress="onlyNumber($event)"
                      @input="
                        $inputNumberInt(
                          $event,
                          managementData,
                          'total_discount',
                        )
                      "
                    />
                    <button
                      @click="
                        managementMode
                          ? reCalDiscount(
                              $event,
                              managementData.product_list,
                              getCompanyInfo(managementData.company_id).vat,
                            )
                          : reCalDiscount(
                              $event,
                              managementData.sales_products,
                              managementData.vat_id,
                            )
                      "
                      class="btn_sub2"
                      v-show="managementMode"
                    >
                      적용
                    </button>
                  </div>
                  <div class="input_text">
                    <div>
                      <p class="warning">
                        {{
                          managementMode
                            ? managementData.sales_products.length > 0
                              ? '₩ ' +
                                calTotalPrice(
                                  managementData.sales_products,
                                  managementData.vat_id,
                                  false,
                                ).tax_total_cost
                              : ''
                            : ''
                        }}
                      </p>
                    </div>
                    <label>과세</label>
                    <input
                      type="text"
                      placeholder=""
                      readonly
                      :value="
                        managementMode
                          ? managementData.product_list.length > 0
                            ? '₩ ' +
                              calTotalPrice(
                                managementData.product_list,
                                getCompanyInfo(managementData.company_id).vat,
                                true,
                              ).tax_total_cost
                            : ''
                          : managementData.sales_products.length > 0
                          ? '₩ ' +
                            calTotalPrice(
                              managementData.sales_products,
                              managementData.vat_id,
                              false,
                            ).tax_total_cost
                          : ''
                      "
                    />
                  </div>
                </div>
                <div>
                  <div class="input_text">
                    <div>
                      <p class="warning">
                        {{
                          managementMode
                            ? managementData.sales_products.length > 0
                              ? '₩ ' +
                                calTotalPrice(
                                  managementData.sales_products,
                                  managementData.vat_id,
                                  false,
                                ).sales_cost
                              : ''
                            : ''
                        }}
                      </p>
                    </div>
                    <label>매출액</label>
                    <input
                      id="sales_cost"
                      type="text"
                      placeholder=""
                      readonly
                      :value="
                        managementMode
                          ? managementData.product_list.length > 0
                            ? '₩ ' +
                              calTotalPrice(
                                managementData.product_list,
                                getCompanyInfo(managementData.company_id).vat,
                                true,
                              ).sales_cost
                            : ''
                          : managementData.sales_products.length > 0
                          ? '₩ ' +
                            calTotalPrice(
                              managementData.sales_products,
                              managementData.vat_id,
                              false,
                            ).sales_cost
                          : ''
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="date_set">
                <div class="input_text">
                  <label>납품요청일</label>
                  <input
                    :disabled="!managementMode"
                    type="date"
                    id="setDate"
                    min=""
                    placeholder=""
                    :value="managementData.delivery_date"
                    @change="setDeliveryDate($event)"
                    :readonly="!managementMode"
                  />
                </div>
                <div class="input_text">
                  <label>수주일</label>
                  <input
                    :disabled="!managementMode"
                    type="date"
                    id="today"
                    placeholder=""
                    :value="managementData.input_date"
                    @change="setInputDate($event)"
                    :readonly="!managementMode"
                  />
                </div>
              </div>
              <div class="input_text">
                <label>납품 완료여부</label>
                <input
                  type="text"
                  disabled
                  :value="managementData.completed == true ? 'Y' : 'N'"
                />
              </div>
              <div class="text_set">
                <div class="input_text">
                  <label>비고</label>
                  <textarea
                    :disabled="!managementMode"
                    type="text"
                    placeholder="수주 내용"
                    v-model="managementData.detail"
                  ></textarea>
                </div>
              </div>
              <div class="btn_wrap">
                <button
                  class="btn_sub2"
                  @click="submitForm"
                  v-show="managementMode"
                  :disabled="isValidModify"
                >
                  수정
                </button>
              </div>
            </div>
            <resource-search
              v-if="showProductSearch"
              :filter_type="2"
              :groupType="2"
              @onclose="showProductSearch = false"
              @onselect="selectProduct($event)"
            ></resource-search>
          </div>
        </div>
      </div>
    </transition>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="deleteSales($event)"
    ></two-button-modal>
  </div>
</template>

<script>
import FetchMixin from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import PriceMixin from '@/mixins/price';
import FavoriteMixin from '@/mixins/favorite';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';
import MySelectric from '@/layouts/components/MySelectric';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import { makeComma } from '@/utils/filters';
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';

export default {
  mixins: [ModalMixin, SpinnerMixin, FetchMixin, PriceMixin, FavoriteMixin],
  components: {
    MySelectric,
    ResourceSearch,
    TwoButtonModal,
  },
  data() {
    return {
      showProductSearch: false,
      showSearchDate: false,

      //delete modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      box_options: 'getBoxForSelectric',
      plan_sales_type_id: 'getPlanSalesType',
      managementMode: 'getManagementModeFromSalesPage',
      managementData: 'getManagementDataFromSales',
      cloneData: 'getCloneDataFromSales',
      manage_products: 'getManagementDataProductListFromSales',
      sales_products: 'getManagementDataSalesProductsFromSales',
      companys: 'getCompany',
      products: 'getProduct',
      units: 'getUnitCodes',
      boxs: 'getBoxsNotSorted',
      sales_src: 'getSales',
      lot_sales: 'getLotSalesFromSales',
      start_date: 'getStartDateFromSales',
      end_date: 'getEndDateFromSales',
      selectedIndex: 'getSelectedIndexFromSales',
      check_only_not_completed: 'getCheckOnlyNotCompletedFromSales',
    }),
    sales() {
      if (this.sales_src == undefined) {
        return [];
      } else {
        if (this.check_only_not_completed) {
          return this.sales_src
            .filter(x => x.completed == false && x.sales_type_id != 2)
            .sort(
              (a, b) => new Date(b.delivery_date) - new Date(a.delivery_date),
            );
        } else {
          return this.sales_src
            .filter(x => x.sales_type_id != 2)
            .sort(
              (a, b) => new Date(b.delivery_date) - new Date(a.delivery_date),
            );
        }
      }
    },
    isWorkOrderd() {
      return this.lot_sales != null &&
        this.lot_sales != undefined &&
        this.lot_sales.length > 0
        ? true
        : false;
    },
    isCompleted() {
      return this.managementData.completed;
    },
    isCalculated() {
      const find = this.managementData.product_list.find(
        x => x.order_yn == true,
      );
      return find != undefined ? true : false;
    },
    isNoItem() {
      if (
        this.managementData.product_list == undefined ||
        this.managementData.product_list.length < 1
      ) {
        return true;
      } else {
        return false;
      }
    },
    isMinusMoney() {
      if (
        this.managementData.product_list != undefined &&
        this.managementData.product_list.length > 0
      ) {
        const find = this.managementData.product_list.find(
          x =>
            Number(String(x.total_cost).replace(/,/gi, '')) <
            Number(String(x.discount).replace(/,/gi, '')),
        );
        return find != undefined ? true : false;
      } else {
        return false;
      }
    },
    isEmpty() {
      let modifyDataProductListLen = this.managementData.product_list.length;

      for (let i = 0; i < modifyDataProductListLen; i++) {
        if (this.managementData.product_list[i].quantity == 0) {
          return true;
        }
      }
      return false;
    },
    validData() {
      if (
        !this.isWorkOrderd &&
        !this.isCompleted &&
        !this.isCalculated &&
        !this.isNoItem &&
        !this.isEmpty &&
        this.checkTotalCostValid
      ) {
        return true;
      } else {
        return false;
      }
    },
    isValidModify() {
      if (this.managementMode) {
        const target = this.lodash.clonedeep(
          this.managementData.sales_products,
        );
        const modify = this.lodash.clonedeep(this.managementData.product_list);
        target.forEach(element => {
          element.quantity = this.$makeNumber(element.quantity);
          element.cost = this.$makeNumber(element.cost);
          element.discount = this.$makeNumber(element.discount);
        });
        modify.forEach(element => {
          element.quantity = this.$makeNumber(element.quantity);
          element.cost = this.$makeNumber(element.cost);
          element.discount = this.$makeNumber(element.discount);
        });

        if (JSON.stringify(target) == JSON.stringify(modify)) {
          const target2 = this.lodash.clonedeep(this.sales[this.selectedIndex]);
          const modify2 = this.lodash.clonedeep(this.managementData);

          if (
            target2 == undefined ||
            target2 == null ||
            modify2 == undefined ||
            modify2 == null ||
            (target2.delivery_date == modify2.delivery_date &&
              target2.input_date == modify2.input_date &&
              target2.detail == modify2.detail)
          ) {
            return true;
          }
          return false;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    checkTotalCostValid() {
      let calPrice;

      if (this.managementMode) {
        calPrice = this.$makeNumber(
          this.calTotalPrice(
            this.lodash.clonedeep(this.managementData.product_list),
            this.getCompanyInfo(this.managementData.company_id).vat,
            true,
          ).total_cost,
        );
      } else {
        calPrice = this.$makeNumber(
          this.calTotalPrice(
            this.lodash.clonedeep(this.managementData.sales_products),
            this.managementData.vat_id,
            false,
          ).total_cost,
        );
      }

      if (calPrice <= 2147483647) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    /**
     * @created jaeone, 2021-08-04
     * @param sales : {... sales_account_list : {create_time : String}[]}
     * @return String 'yyyy-mm-dd';
     * @description 납품기록(sales_account) 중 가장 최신의 날짜를 return함
     */
    getLastOutputDay(sales) {
      if (
        Object.keys(sales).includes('sales_account_list') &&
        sales.sales_account_list.length > 0
      ) {
        const copied_list_ = this.lodash.clonedeep(sales.sales_account_list);
        const lastCreateTime = copied_list_
          .map(x => new Date(x.create_time))
          .sort((a, b) => b - a)[0];
        return yyyymmdd(lastCreateTime);
      } else {
        return '-';
      }
    },
    checkAndReplaceChildQuantity(parent_product) {
      const parent = this.findInfoFromId(
        this.products,
        parent_product.product_id,
      );

      if (parent.product_group_yn && parent.product_group_type_id == 2) {
        const sumProudctQuantity = this.managementData.product_list
          .filter(x => x.product_id == parent_product.product_id)
          .map(x =>
            this.$decimalMul(
              this.$makeNumber(x.quantity),
              this.getBoxQuantity(x.box_unit_id),
            ),
          )
          .reduce((a, b) => this.$decimalAdd(a, b), 0);

        this.managementData.product_list = this.managementData.product_list.map(
          x => {
            if (x.parent_product_id == parent_product.product_id) {
              x.quantity = this.$decimalMul(
                sumProudctQuantity,
                parent.product_group_list.find(
                  y => y.member_product_id == x.product_id,
                ).quantity,
              );
            }
            return x;
          },
        );
      }
    },
    reCalCost(row, newSalesValue) {
      const total_value =
        this.$makeNumber(newSalesValue) + this.$makeNumber(row.discount);
      console.log('total_value', total_value);
      const company_vat = this.companys.find(
        x => x.id == this.managementData.company_id,
      ).vat;
      const product_tax = row.tax_id;
      let rowQuantity = this.$decimalMul(
        this.$makeNumber(row.quantity),
        row.box_unit_id != null ? this.getBoxQuantity(row.box_unit_id) : 1,
      );
      if (rowQuantity === 0) {
        row.quantity = 1;
        rowQuantity = 1;
      }

      if (company_vat != 1 || product_tax == 2) {
        row.cost = 0;
        row.cost = this.$makeNumber(
          this.$decimalDiv(total_value, this.$makeNumber(rowQuantity)).toFixed(
            0,
          ),
        );
      } else {
        const new_total_value = this.$makeNumber(
          this.$decimalDiv(total_value, 1.1).toFixed(0),
        );
        row.cost = 0;
        row.cost = this.$makeNumber(
          this.$decimalDiv(
            new_total_value,
            this.$makeNumber(rowQuantity),
          ).toFixed(0),
        );
      }
    },
    copyData() {
      this.$store.commit(
        'setNewDataToSales',
        this.lodash.clonedeep(this.managementData),
      );
      this.$store.commit('setOpenTabIndexToSalesPage', 1);
    },
    async deleteSales(arg1) {
      console.log(arg1);
      if (arg1 == undefined) return;
      this.showSpinner();
      //수주 삭제
      this.$store
        .dispatch('DELETE_SALES', this.managementData.id)
        .then(() => {
          this.FETCH_SALES();
          this.selectRow(-1);
        })
        .catch(() => {
          this.openOneButtonModal('삭제 오류', '삭제 중 오류 발생.');
        })
        .finally(() => {
          this.hideSpinner();
          this.CloseModal();
        });
    },
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    deleteProductListItem(sales_product, index) {
      this.managementData.product_list.splice(index, 1);

      const setProductChk = this.findInfoFromId(
        this.products,
        sales_product.product_id,
      );
      if (
        setProductChk.product_group_yn &&
        setProductChk.product_group_type_id == 2 &&
        this.managementData.product_list.find(
          x => x.product_id == sales_product.product_id,
        ) == undefined
      ) {
        this.managementData.product_list = this.managementData.product_list.filter(
          x => x.parent_product_id != sales_product.product_id,
        );
      } else {
        this.checkAndReplaceChildQuantity(sales_product);
      }
      // this.$store.commit(
      //   'deleteManagementDataProductListItem',
      //   sales_product,
      //   index,
      // );
    },
    selectRow(index) {
      this.$store.commit('setSelectedIndexToSales', index);
      if (index != -1) {
        this.$store.commit(
          'setManagementDataToSales',
          this.lodash.clonedeep(this.sales[index]),
        );
        this.$store.commit(
          'setCloneDataToSales',
          this.lodash.clonedeep(this.managementData),
        );
        this.FETCH_LOT_SALES(this.sales[index].id);
      }
    },
    getSaleCost(index) {
      let sale_cost = 0;
      this.sales[index].product_list.forEach(el => {
        sale_cost += el.total_cost - el.discount;
      });
      return makeComma(sale_cost);
    },
    getFirstSalesProduct(index) {
      const productCount = this.lodash
        .clonedeep(this.sales[index].product_list)
        .filter(x => x.parent_product_id == null);
      const firstProduct = this.getProductInfo(productCount[0].product_id).name;
      return productCount.length > 1
        ? firstProduct + ' 외 ' + Number(productCount.length - 1) + '개 제품'
        : firstProduct;
    },
    async setStartDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setStartDateToSales', e.target.value);
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '납품요청일 오류',
            `납품요청일 최후 날짜(${this.end_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToSales', e.target.value);
          this.selectRow(-1);
          await this.FETCH_SALES();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToSales', yyyymmdd(new Date()));
      }
    },
    async setEndDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setEndDateToSales', e.target.value);
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '납품요청일 오류',
            `납품요청일 최초 날짜(${this.start_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToSales', e.target.value);
          this.selectRow(-1);
          await this.FETCH_SALES();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToSales', yyyymmdd(new Date()));
      }
    },

    async setInputDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setEndDateToSales', e.target.value);
        if (e.target.value > this.managementData.delivery_date) {
          this.openOneButtonModal(
            '수주일 오류',
            `납품요청일(${this.managementData.delivery_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.managementData.input_date;
        } else {
          this.managementData.input_date = e.target.value;
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
      }
    },
    async setDeliveryDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setStartDateToSales', e.target.value);
        if (e.target.value < this.managementData.input_date) {
          this.openOneButtonModal(
            '납품요청일 오류',
            `수주일(${this.managementData.input_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.managementData.delivery_date;
        } else {
          this.managementData.delivery_date = e.target.value;
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
      }
    },

    async toggleCheckBox() {
      this.$store.commit('toggleCheckOnlyNotCompletedToSales');
      await this.FETCH_SALES();
    },
    async submitForm() {
      if (this.validData == true) {
        const payload = this.managementData;
        payload.vat_id = this.getCompanyInfo(
          this.managementData.company_id,
        ).vat;

        await payload.product_list.forEach(el => {
          el.cost = this.$makeNumber(el.cost);
          console.log('beforeDIscount', el.discount);
          el.discount = this.$makeNumber(el.discount);
          console.log('afterDIscount', el.discount);
          el.tax_id = this.getProductInfo(el.product_id).tax;
          el.supply_value = this.calSupplyTax(
            this.getCompanyInfo(this.managementData.company_id).vat,
            this.getProductInfo(el.product_id).tax,
            this.$makeNumber(el.quantity) * this.getBoxQuantity(el.box_unit_id),
            this.$makeNumber(el.cost),
          ).supply;
          el.tax = this.calSupplyTax(
            this.getCompanyInfo(this.managementData.company_id).vat,
            this.getProductInfo(el.product_id).tax,
            this.$makeNumber(el.quantity) * this.getBoxQuantity(el.box_unit_id),
            this.$makeNumber(el.cost),
          ).tax;
          el.quantity = this.$makeNumber(el.quantity);
          // el.tax = this.calSupplyTax(
          //   this.getCompanyInfo(this.managementData.company_id).vat,
          //   this.getProductInfo(el.product_id).tax,
          // this.$makeNumber(//   el).quantity * this.getBoxQuantity(el.box_unit_id),
          //   this.$makeNumber(el.cost),
          // ).tax;
          el.total_cost = this.calSupplyTax(
            this.getCompanyInfo(this.managementData.company_id).vat,
            this.getProductInfo(el.product_id).tax,
            this.$makeNumber(el.quantity) * this.getBoxQuantity(el.box_unit_id),
            this.$makeNumber(el.cost),
          ).total;
        });
        payload.sales_products = this.lodash.clonedeep(payload.product_list);
        // console.log('payload', payload);
        // await this.$store.commit('setManagementDataToSales', payload);
        if (!this.isMinusMoney) {
          console.log(payload);
          this.showSpinner();
          this.$store
            .dispatch('UPDATE_SALES_ALL', payload)
            .then(() => {
              this.openOneButtonModal(
                '수정 성공',
                '성공적으로 수주를 수정하였습니다.',
              );
              this.FETCH_SALES();
              this.selectRow(-1);
            })
            .catch(error => {
              console.log('실패 ' + error);
              this.openOneButtonModal(
                '수정 실패',
                '수주 수정 중 오류가 발생했습니다.',
              );
            })
            .finally(() => {
              this.hideSpinner();
            });
        } else {
          this.openOneButtonModal(
            '수정 불가',
            '할인액은 제품 합계액을 넘길 수 없습니다.',
          );
        }
      } else {
        if (this.isCompleted) {
          this.openOneButtonModal(
            '수정 불가',
            '이미 납품이 완료된 수주는 수정 및 삭제가 불가능합니다.',
          );
        } else if (this.isCalculated) {
          this.openOneButtonModal(
            '수정 불가',
            '소요량 산출을 통해 이미 발주처리된 수주제품이 존재함으로 수정 및 삭제가 불가능합니다.',
          );
        } else if (this.isWorkOrderd) {
          this.openOneButtonModal(
            '수정 불가',
            '작업지시서가 등록된 수주제품이 존재함으로 수정 및 삭제가 불가능합니다.',
          );
        } else if (this.isNoItem) {
          this.openOneButtonModal(
            '수정 불가',
            '반드시 하나 이상의 수주품목을 등록하여주십시오.',
          );
        } else if (this.isEmpty) {
          this.openOneButtonModal('수정 불가', '수량을 입력해 주십시오.');
        } else if (!this.checkTotalCostValid) {
          this.openOneButtonModal(
            '합계액 초과',
            '1회 수주 최대 금액은<br />20억 이상 수주할 수 없습니다.',
          );
        }
      }
    },
    selectProduct(arg) {
      const groupProudctChk = this.findInfoFromId(this.products, arg.id)
        .product_group_yn;

      if (
        groupProudctChk &&
        this.lodash
          .clonedeep(this.managementData.product_list)
          .find(x => x.product_id == arg.id) != undefined
      ) {
        this.openOneButtonModal(
          '이미 등록된 세트제품',
          '세트제품은 수주당 하나만 등록할 수 있습니다.',
        );
      } else {
        const findChildIndex = this.managementData.product_list.findIndex(
          x => x.parent_product_id == arg.id,
        );
        if (findChildIndex == -1) {
          this.managementData.product_list.push({
            product_id: arg.id,
            unit_id: arg.stock_unit_id,
            box_unit_id: arg.box_unit_id,
            standard: arg.standard,
            quantity: 0,
            cost: 0, //단가
            supply_value: 0, //공급가
            tax: 0, //부가세
            total_cost: 0, //합계액
            discount: '0', //단일 할인액
            tax_id: arg.tax,
            parent_product_id: null,
          });
        } else {
          this.managementData.product_list.splice(findChildIndex, 0, {
            product_id: arg.id,
            unit_id: arg.stock_unit_id,
            box_unit_id: arg.box_unit_id,
            standard: arg.standard,
            quantity: 0,
            cost: 0, //단가
            supply_value: 0, //공급가
            tax: 0, //부가세
            total_cost: 0, //합계액
            discount: '0', //단일 할인액
            tax_id: arg.tax,
            parent_product_id: null,
          });
        }

        const setProductChk = this.findInfoFromId(this.products, arg.id);
        if (
          setProductChk.product_group_yn == true &&
          setProductChk.product_group_type_id == 2 &&
          this.managementData.product_list.find(
            x => x.parent_product_id == arg.id,
          ) == undefined
        ) {
          setProductChk.product_group_list.map(x => {
            const setMemberProduct = this.findInfoFromId(
              this.products,
              x.member_product_id,
            );

            this.managementData.product_list.push({
              product_id: setMemberProduct.id,
              unit_id: setMemberProduct.stock_unit_id,
              box_unit_id: null,
              standard: setMemberProduct.standard,
              quantity: 0,
              cost: 0, //단가
              supply_value: 0, //공급가
              tax: 0, //부가세
              total_cost: 0, //합계액
              discount: '0', //단일 할인액
              tax_id: setMemberProduct.tax,
              parent_product_id: x.group_product_id,
            });
          });
        }

        this.showProductSearch = false;
      }
    },
    getBoxQuantity(id) {
      let hit = this.boxs.find(x => x.id == id);
      return hit != undefined ? hit.quantity : 1;
    },
    getProductInfo(id) {
      let hit = this.products.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getCompanyInfo(id) {
      let hit = this.companys.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getUnitName(id) {
      let hit = this.units.find(x => x.id == id);
      return hit != undefined ? hit.name : '';
    },
    pushNewCompany(arg) {
      this.managementData.company_id = arg.id;
      this.showCompanySearch = false;
    },

    async FETCH_SALES() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_SALES', {
          start_date: this.start_date,
          end_date: this.end_date,
          check_only_not_completed: false,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '수주 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async FETCH_LOT_SALES(sales_id) {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_LOT_SALES', sales_id)
        .then(() => {})
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },

  async created() {
    if (this.plan_sales_type_id == undefined) {
      await this.FETCH('FETCH_SALES_TYPE', '판매 유형');
    }
    if (this.box_options.length < 2) {
      await this.FETCH('FETCH_BOX', '박스');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.start_date == null) {
      var date = new Date();
      this.$store.commit('setStartDateToSales', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() + 14));
      this.$store.commit('setEndDateToSales', yyyymmdd(date2));
      await this.FETCH_SALES();
    }
    this.showSearchDate = true;

    if (this.sales.length < 1) {
      await this.FETCH_SALES();
    }

    if (this.managementData.input_date == null) {
      var date3 = new Date();
      this.managementData.input_date = yyyymmdd(date3);
      var date4 = new Date(date.setDate(date.getDate() + 7));
      this.managementData.delivery_date = yyyymmdd(date4);
    }
    this.managementData.sales_type_id = this.plan_sales_type_id.id;
  },
};
</script>

<style>
/* 애니메이션 진입 및 진출은 다른 지속 시간 및  */
/* 타이밍 기능을 사용할 수 있습니다. */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.55s ease-in-out;
}
.slide-fade-enter {
  transform: translateX(100%);
}
.slide-fade-leave-to {
  transform: translateX(100%);
}
</style>
